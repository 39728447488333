import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import Banner from "../partials/Banner";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import { tailwindConfig, hexToRGB, formatValue } from "../utils/Utils";
import { FaArrowRight, FaArrowLeft, FaAdjust, FaLaptopHouse, FaCoins, FaChartLine, FaArrowAltCircleDown, FaArrowAltCircleUp, FaPauseCircle } from "react-icons/fa";
import { getGPTAnalysis, getRLAlgorithms } from "../serives/backendService";
import Select from "react-select";
import LineChart from "../charts/LineChart03";
import DoughnutChart from "../charts/DoughnutChart";
import { PieChart } from "react-minimal-pie-chart";
import BarChart01 from "../charts/BarChart01";
import NumberFormat from "react-number-format";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  height: "500px",
  plugins: {
    legend: {
      position: 'None',
    },
    title: {
      display: true,
      text: 'NLP Insight',
    },
  },
  scales: {
    y: {
      min: -1.5,
      max: +1.5,
    },

  }
};

const labels = ['2:00 pm', '3:00 pm', '4:00 pm', '5:00 pm', '6:00 pm', '7:00 pm', '8:00 pm'];

class GPT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnline: null,
      assetname: "",
      sidebarOpen: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Forecast (%)',
            data: [],
            backgroundColor: [],
          },

        ],
      },
      positiveDev: [],
      potentialConcerns: [],
      predictionPct: 0,
      analysis: "",
      timerUpdate: null,
      asset_default: {"label":"CURRENT", "value":"CURRENT"},
      asset_list: [{"label":"CURRENT", "value":"CURRENT"}],
    };
    
    this.updateData = this.updateData.bind(this);
    this.changeAsset = this.changeAsset.bind(this);
    this.interval = null;

  }

  changeAsset(asset){
    if(asset.value == "CURRENT"){
      this.updateData("") 
    }
    else
    {
      this.updateData(asset.value)
    }
    
    this.setState({ ...this.state, asset_default: asset });
    
  }

  updateData(asset) {
    getGPTAnalysis(asset).then(res => {
      console.log("WORKING!!!")
      var gptOutputJson = JSON.parse(res.gptOutput)
      this.setState({
        ...this.state, assetname: res.name, positiveDev: gptOutputJson.POSITIVE_DEVELOPMENTS, potentialConcerns: gptOutputJson.POTENTIAL_CONCERNS, analysis: gptOutputJson.PREDICTION_ANALYSIS,
        predictionPct: gptOutputJson.PCT_PREDICTION,
        
        chartData: {
          labels: res.dateTime,
          datasets: [
            {
              label: 'Forecast (%)',
              data: res.values,
              backgroundColor: res.colours,
            },
          ],
        },
      })
    }).then(() => {
      getRLAlgorithms().then((res) => {
        var asset_list = res['data'].map(item => {
          return {"label":item, "value": item}
        })
        asset_list = [{"label":"CURRENT", "value":"CURRENT"}].concat(asset_list)
        this.setState({ ...this.state, asset_list: asset_list});
      })
    })
  }


  componentWillUnmount() {
    if (this.timerUpdate != null) clearInterval(this.timerUpdate);
  }

  componentDidMount() {
    this.updateData("")
    this.timerUpdate = setInterval(() => {
      this.updateData("");
    }, 60000);
  }

  render() {
    const { labels, actualResults, volume } = this.state;
    // const chartConfig = getConfig(labels, actualResults, volume);

    return (
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={this.state.sidebarOpen} setSidebarOpen={() => this.setSidebarOpen(false)} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header setSidebarOpen={() => this.setSidebarOpen(true)} />
          {/* <Header /> */}

          <main>
            <div class="mx-auto">

              <div class="px-0 " style={{ background: "black" }}>
                <div class="px-0 mx-auto">
                  <div class="flex flex-row content-center self-center bg-blue">
                    <div style={{ height: "50px", width: "60px", overflow: "hidden" }} className="ml-6">
                      <img style={{ objectFit: "fill" }} className="w-full" src={process.env.PUBLIC_URL + '/dashboardlogo.png'} alt="Login" />
                    </div>

                    <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-2xl tracking-wider ml-4  text-blue-900 font-bold uppercase justify-center self-center ">FinanceGPT</span>
                  </div>
                </div>
              </div>


              <section style={{ fontFamily: "Roboto !important" }} class="m-2 bg-gray-700">
                <div class=" px-4 py-2 mx-auto">
                  <div class="text-white mb-2">
                    <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center ">Asset Analysis</span>
                  </div>
                  <div class="flex flex-wrap bg-white">
                    <div class="flex-1 p-4 rounded-2xl bg-blend-color bg-white">
                      <div className="w-full px-4 sm:px-6 lg:px-8  w-full max-w-9xl mx-auto">

                      <div className="sm:flex m-4 sm:justify-between sm:items-center mb-8">
                            {/* Left: Avatars */}
                            {/* <DashboardAvatars /> */}
                            <div style={{ width: "300px" }} >
                              Asset
                              <Select width="400px" value={this.state.asset_default} style={{ width: "600px" }} onChange={this.changeAsset} options={this.state.asset_list} />
                            </div>
                            {/* Right: Actions */}
                       </div>
                        {/* Cards */}
                        <div className="grid grid-cols-12 gap-6">
                          

                          <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-9 md:col-span-6 bg-white shadow-lg border border-gray-200 rounded-2xl bg-blend-color bg-white">
                            <div className="row mt-4 mb-4">
                              <span className="text-md tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center mt-2">ASSET: {this.state.assetname} </span>
                            </div>
                            <div className="row">
                              <span className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center mt-2">Positive Developments</span>
                              {this.state.positiveDev?.map((item, i) => {
                                return (
                                  <div className="m-4"><b>{i + 1}. {item.title} - </b> {item.reasoning}</div>
                                )
                              })}
                            </div>
                            <div className="row">
                              <span className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center mt-2">Potential Concerns</span>
                              {this.state.potentialConcerns?.map((item, i) => {
                                return (
                                  <div className="m-4"><b>{i + 1}. {item.title} - </b> {item.reasoning}</div>
                                )
                              })}

                            </div>
                            <div className="row">
                              <span className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center mt-2">Prediction & Analysis</span>

                              <div className="m-4"><b>Prediction: {this.state.predictionPct}</b>
                              </div>
                              <div className="m-4"><b>Analysis: </b> {this.state.analysis}
                              </div>
                            </div>


                          </div>

                          <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-3 md:col-span-6 bg-white shadow-lg border border-gray-200 rounded-2xl bg-blend-color bg-white">
                            <div className="m-4" style={{ height: "600px" }}>
                              <Bar options={options} width={100}
                                height={150} data={this.state.chartData} />
                            </div>
                            <div>

                              <div class="p-4 rounded-2xl bg-blend-color bg-white h-40">
                                <div class="flex mb-2">
                                  <span class="inline-block mr-2">

                                  </span>
                                  <h3 class="text-gray-600 font-bold">Next Hour Prediction</h3>
                                </div>
                                <h2 class="font-bold pt-5 text-3xl text-center">
                                  <NumberFormat value={1} displayType={"text"} thousandSeparator={true} renderText={(formattedValue) => <div style={this.state.predictionPct >= 0 ? { color: "green" } : { color: "red" }}>{this.state.predictionPct >= 0 ? "UP " : "DOWN "} + {Number(this.state.predictionPct).toFixed(2) } </div>} />
                                </h2>

                              </div>

                            </div>

                          </div>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
              </section>


            </div>
          </main>

          <Banner />
        </div>
      </div>
    );
  }
}

export default GPT;
