import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import Banner from "../partials/Banner";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";

import { getListHistoryTransactions } from "../serives/backendService";
import Select from "react-select";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import moment from "moment";
import { FaArrowRight, FaArrowLeft, FaList } from "react-icons/fa";

class TransactionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listOfTransactions: [],
      listOfTransactionsFilter: [],

      buttonPressed: "today",
      sidebarOpen: false,
      asset_default: {"label":"ALL", "value":"ALL"},
      asset_list: [{"label":"ALL", "value":"ALL"}]

    };
    this.runStatusTimer = null
    this.rangeButtonClick = this.rangeButtonClick.bind(this);
    this.filterData = this.filterData.bind(this);
    this.setSidebarOpen = this.setSidebarOpen.bind(this)
    this.changeAsset = this.changeAsset.bind(this);
  }


  changeAsset(asset){
    // var filterList = this.state.listOfTransactions
    // if(asset.value != "ALL")
    //     filterList = filterList.filter(item=> {
    //     return item.symbol == asset.value
    //   })
    this.setState({ ...this.state, asset_default: asset });
    this.filterData(this.state.buttonPressed, "")
  }

  setSidebarOpen(statusLocal) {
    console.log("Executed!!!")
    console.log(this.state.sidebarOpen)
    this.setState({...this.state, sidebarOpen: statusLocal})
  }
  
  filterData(range, asset) {
    getListHistoryTransactions(asset).then((res) => {
      var listOfTransactions = res;
      
      var unique = res.map((item) => {
        return item.symbol
      })

      
      unique = unique.filter((value, index, array) => array.indexOf(value) === index);
      unique  = unique.map(item=> {
        return {"label":item, "value":item}
      })
      unique = [{"label":"ALL", "value":"ALL"}].concat(unique)


      var filterList = [];
      switch (range) {
        case "today":
          let startToday = moment().startOf('day');
          let endToday = new Date();
          filterList = listOfTransactions.filter((item) => {
            let date = new Date(item.time);
            return date >= startToday && date <= endToday;
          });
          break;
        case "week":
          let startWeek = moment().subtract(1, "weeks");
          let endWeek = new Date();
          filterList = listOfTransactions.filter((item) => {
            let date = new Date(item.time);
            return date >= startWeek && date <= endWeek;
          });
          break;
        case "month":
          let startMonth = moment().subtract(1, "months");
          let endMonth = new Date();
          filterList = listOfTransactions.filter((item) => {
            let date = new Date(item.time);
            return date >= startMonth && date <= endMonth;
          });
          break;
        case "all":
          filterList = listOfTransactions.filter((item) => {
            return true;
          });
          break;
        default:
          break;
      }
      filterList = filterList.sort((a, b) => a.time > b.time ? -1 : 1)
      if(this.state.asset_default.value != "ALL")
        filterList = filterList.filter(item=> {
          return item.symbol == this.state.asset_default.value
        })

      // filterList = filterList.reverse();
      this.setState({ ...this.state, listOfTransactionsFilter: filterList, listOfTransactions: listOfTransactions, asset_list: unique, buttonPressed: range });
    }).catch((e) => { console.log(e) });
  }

  rangeButtonClick(range) {
    this.filterData(range, "");
    this.setState({ ...this.state, buttonPressed: range });
  }

  componentDidMount() {
    this.filterData(this.state.buttonPressed, "");
    if (this.runStatusTimer == null)
      this.runStatusTimer = setInterval(() => {
        this.filterData(this.state.buttonPressed, "");
        console.log("called")
      }, 10000)
  }

  componentWillUnmount() {
    if (this.runStatusTimer != null)
      clearInterval(this.runStatusTimer)
    this.runStatusTimer = null;
  }

  render() {
    return (
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={this.state.sidebarOpen} setSidebarOpen={() => this.setSidebarOpen(false)} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header  setSidebarOpen={() => this.setSidebarOpen(true)} />

          <main>


            <div class="px-0 " style={{ background: "black" }}>
              <div class="px-0 mx-auto">
                <div class="flex flex-row content-center self-center bg-blue">
                  <div style={{ height: "50px", width: "60px", overflow: "hidden" }} className="ml-6">
                    <img style={{ objectFit: "fill" }} className="w-full" src={process.env.PUBLIC_URL + '/dashboardlogo.png'} alt="Login" />
                  </div>

                  <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-2xl tracking-wider ml-4  text-blue-900 font-bold uppercase justify-center self-center ">History</span>
                </div>
              </div>
            </div>
            <div className="sm:flex m-4 sm:justify-between sm:items-center mb-8">
                  {/* Left: Avatars */}
                  {/* <DashboardAvatars /> */}
                  <div style={{ width: "300px" }} >
                    Asset
                    <Select width="400px" value={this.state.asset_default} style={{ width: "600px" }} onChange={this.changeAsset} options={this.state.asset_list} />
                  </div>
                  {/* Right: Actions */}
            </div>

            <section style={{ fontFamily: "Roboto !important" }} class="m-2 bg-gray-700">
              <div class="px-4 py-2 mx-auto">
                 <div class="flex text-white mb-2">
                <div>
                    <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center ">Transactions</span>
                    </div>
                    <div class="ml-auto">
                      <button class={`py-2 px-3 text-xs  ${this.state.buttonPressed === "today" ? "bg-white text-gray-900 focus:border-none" : "text-gray-200"} font-medium  `} onClick={() => this.rangeButtonClick("today")}>
                        Today
                      </button>
                      <button class={`py-2 px-3 text-xs  ${this.state.buttonPressed === "week" ? "bg-white text-gray-900 focus:border-none" : "text-gray-200"} font-medium  `} onClick={() => this.rangeButtonClick("week")}>
                        Week
                      </button>
                      <button class={`py-2 px-3 text-xs  ${this.state.buttonPressed === "month" ? "bg-white text-gray-900 focus:border-none" : "text-gray-200"} font-medium  `} onClick={() => this.rangeButtonClick("month")}>
                        Month
                      </button>
                      <button class={`py-2 px-3 text-xs  ${this.state.buttonPressed === "all" ? "bg-white text-gray-900 focus:border-none" : "text-gray-200"} font-medium  `} onClick={() => this.rangeButtonClick("all")}>
                        All
                      </button>
                    </div>
                  
                  </div>
                  <div class="bg-white">
                  <div class="">
                    <table class="text-left w-full">
                      <thead class="flex w-full">
                        <tr class="text-xs text-white bg-gray-800 text-left flex w-full">
                          <th class="p-4 w-1/4 font-medium text-center font-bold">ID</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Transaction Open Date</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Transaction Close Date</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Symbol</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Status</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Price Open (USD)</th>

                          <th class="p-4 w-1/4 font-medium text-center font-bold">Price Buy (USD)</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Price Close (USD)</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Profit/Loss</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Profit/Loss (%)</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Qty</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Allocated (USD)</th>
                          <th class="p-4 w-1/4 font-medium text-center font-bold">Transaction Cost (USD)</th>
                        </tr>
                      </thead>
                      <tbody class="flex flex-col items-center justify-between overflow-y-scroll w-full" style={{ maxHeight: "70vh" }}>
                        {this.state.listOfTransactionsFilter.map((item, index) => (
                          <tr class={`text-xs ${index % 2 ? "bg-white-50" : "bg-gray-50"}  flex w-full`}>
                            <td class="p-4 w-1/4 font-medium text-center mt-4">{item.id}</td>
                            <td class="p-4 w-1/4 text-center mt-4">
                              <Moment>{item.timeOpen}</Moment>
                            </td>
                            <td class="p-4 w-1/4 text-center mt-4">
                              {item?.timeClose != "" ? <Moment>{item?.timeClose}</Moment> : "OPEN"
                              }
                            </td>
                            <td class="p-4 w-1/4">
                              <div class="text-center">
                                {/* <img class="w-8 h-8 rounded-md m-auto" src={`/cryptocurrency-icons/128/color/${ String(item.symbol).replace("USDT","").toLowerCase()}.png`} alt="" /> */}
                                <p class="font-medium text-center mt-4">{item.symbol}</p>
                              </div>
                            </td>
                            <td class="p-4 w-1/4">
                              <div class="text-center">
                                <p class="font-medium text-center mt-4">{item.status}</p>
                              </div>
                            </td>
                            {/* ffff */}
                            <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                              <NumberFormat value={Number(item.openOrder).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                            </td>

                            <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                              <NumberFormat value={Number(item.open).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                            </td>
                            <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                              <NumberFormat value={Number(item.close).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                            </td>
                            <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                              <NumberFormat value={Number(item.profit).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                            </td>
                            <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                              <NumberFormat value={Number(item.pct).toFixed(2)} displayType={"text"} thousandSeparator={true} suffix={"%"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                            </td>
                            <td class="p-4 w-1/4 font-medium text-center mt-4 ">{item.qty}</td>
                            <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                              <NumberFormat value={Number(item.open * item.qty).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                            </td>

                            {/* fff */}
                            <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                              <NumberFormat value={Number(item.TradeCostsTotal).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
               </div>

              </div>
            </section>
          </main>

          <Banner />
        </div>
      </div>
    );
  }
}

export default TransactionHistory;
