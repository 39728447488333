const serverIP = "https://cryptorl1.ddns.net:5000";
// const serverIP = "http://127.0.0.1:5001";

var userName = "";
var password = "";

export function getPasswordEncoded()
{
  return btoa(userName + ":" + password)
}

export function getData(index, model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getData?index=${index}&model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getUsername() {
  return userName
}

export function getSentimentAnalysis(index, model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getSentimentAnalysis?index=${index}&model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getSentimentAnalysisNews(index, model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getSentimentAnalysisNews?index=${index}&model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function removeUser(id) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/removeUser?id=${id}`)
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function changeUserPassword(id, passwordIn) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());
  return fetch(`${serverIP}/changeUserPassword?id=${id}&newpassword=${passwordIn}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function addUser(user, password) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/addUser?user=${user}&password=${password}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function checkAuthentication(user, passwordIn) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());
  userName = user
  password = passwordIn

  return fetch(`${serverIP}/checkAuthentication?username=${user}&password=${passwordIn}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function changeModel(model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/changeModel?model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getModels() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getModels`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getLiveIndexes(model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getLiveIndexes?model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getPredictionChartData(index, model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getPredictionChartData?index=${index}&model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getConfidenceData(index, model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getConfidence?index=${index}&model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getTweets(index, model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getTweets?index=${index}&model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getLiveSentiment(index, model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getLiveSentiment?index=${index}&model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getNews(index, model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getNews?index=${index}&model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getLiveSentimentNews(index, model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getLiveSentimentNews?index=${index}&model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getIndexesForModel(model) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getIndexesForModel?model=${model}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getUserList() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/getUsers`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getBotConfig() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/botConfig`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getCurrentAsset() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/currentAsset`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function postBotConfig(fundUsedPct, minConfThreshold, minPredValue, symbols, stopLoss, keepGain, rlModel ) {
  return fetch(`${serverIP}/botConfig`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": "Basic " + getPasswordEncoded()
    },
    body: JSON.stringify({ fundUsedPct: fundUsedPct, minConfThreshold: minConfThreshold, minPredValue: minPredValue, symbols: symbols, stopLoss: stopLoss, keepGain: keepGain, RLModel: rlModel }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
}

export function postBotRunStatus(status) {

  return fetch(`${serverIP}/botRunStatus`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": "Basic " + getPasswordEncoded()
    },
    body: JSON.stringify({ status: status }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getAccountData() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/accountData`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getBotRunStatus() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/botRunStatus`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}


export function getRLPairData(pair) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/rLListHistorySinglePair?pair=${pair}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getRLInputData() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());
  return fetch(`${serverIP}/rLListHistoryInputPairs`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getRLAlgorithmConfig(algorithm) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/rLAlgorithmConfig?algorithm=${algorithm}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}


export function getRLAlgorithms() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/rLAlgorithms`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getRLBackTest(algorithm, pair, baseOrderLimit, maxOrder, stopLoss, keepGain, skipPoints, long, noPoints) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/rLBackTest?algorithm=${algorithm}&pair=${pair}&baseOrderLimit=${baseOrderLimit}&maxOrder=${maxOrder}&stopLoss=${stopLoss}&keepGain=${keepGain}&skipPoints=${skipPoints}&long=${long}&noPoints=${noPoints}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}



export function getListHistoryTransactions(asset) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/listHistoryTransactions?asset=${asset}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}


export function getUseModelData() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/useModelData`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function postUseModelData(objLoc) {
  return fetch(`${serverIP}/useModelData`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": "Basic " + getPasswordEncoded()
    },
    body: JSON.stringify(objLoc),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getCurrentModelData() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/currentModelData`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}


export function getRefreshTestData(pair, no) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/refreshTestData?pair=${pair}&no=${no}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}



export function postCurrentModelData(objLoc) {
  return fetch(`${serverIP}/currentModelData`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": "Basic " + getPasswordEncoded()
    },
    body: JSON.stringify(objLoc),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function getRLLive() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/rLLive`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}


// --------
export function getControl() {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/botControl`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}


export function getGPTAnalysis(asset) {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + getPasswordEncoded());

  return fetch(`${serverIP}/gptAnalysis?asset=${asset}`, { method: "GET", headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

export function postControl(mode) {
  return fetch(`${serverIP}/botControl`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": "Basic " + getPasswordEncoded()
    },
    body: JSON.stringify({ mode: mode }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
}
